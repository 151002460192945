<template>
  <div class="topRight">
    <div class="once">更换检测</div>
    <div class="once">打印报告</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.topRight{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0.53rem;
  right: 1.24rem;
  .once{
    width: 1.39rem;
    height: 0.57rem;
    background-image: url('../../../assets/images/top-r.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #EDF5FC;
    font-size: 0.2rem;
    font-weight: bold;
    margin-left: 0.33rem;
  }
}
</style>