<template>
  <div class="bootomLeft">
    <div class="once">
      <div>XXXX</div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"></div>
    </div>
    <div class="once once-r">
      <div>XXXX</div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"><img src="@/assets/images/bl1.png" alt=""></div>
      <div class="border-is"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.bootomLeft{
  height: 0.4rem;
  line-height: 04rem;
  padding:  0 0.06rem;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 1.2rem;
  bottom: 0.44rem;
  .once{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.16rem;
    font-weight: bold;
    .border-is{
      width: 0.19rem;
      height: 0.19rem;
      background-image: url('../../../assets/images/border1.png');
      background-size: 100% 100%;
      // padding: 1px;
      // box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.06rem;
      img{
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }
  .once-r{
    margin-left: 0.5rem;
  }
}
</style>