<template>
  <div class="centerRight">
    <div>{{form.血糖}}</div>
    <div>mmol/L</div>
  </div>
</template>

<script>
export default {
  props:{
    form: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.centerRight{
  width: 2.49rem;
  height: 2.49rem;
  position: absolute;
  top: 1.75rem;
  right: 4.07rem;
  background-image: url('../../../assets/images/right.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.4rem;
}
</style>