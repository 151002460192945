<template>
  <div class="centerRight">
    <div>{{preasure.high}}</div>
    <div>{{preasure.low}}</div>
    <div>{{form.心率}}</div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      preasure: {
        high: '-',
        low: '-'
      }
    }
  },
  props:{
    form: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    form: {
      handler: function(){
        let str = this.form
        if(str['血压'] && str['血压'] != '-'){
          let lt = str['血压'].split('/')
          this.preasure.high = lt[0] ? lt[0] : '-'
          this.preasure.low = lt[1] ? lt[1] : '-'
        }
        if(str['血压'] == '-'){
          this.preasure.high = '-'
          this.preasure.low = '-'
        }
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="less" scoped>
.centerRight{
  width: 2.49rem;
  height: 2.49rem;
  position: absolute;
  top: 1.75rem;
  left: 3.87rem;
  background-image: url('../../../assets/images/left.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.4rem;
}
</style>