<template>
  <div class="login">
    <div class="tit">账号密码登录</div>
    <div class="form">
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="账号：">
          <a-input v-model="form.account" placeholder="请输入账号" />
        </a-form-model-item>
        <a-form-model-item label="密码：">
          <a-input type="password" v-model="form.password" placeholder="请输入密码" />
        </a-form-model-item>
      </a-form-model>
      <div class="login-btn">
        <a-button class="btn" type="primary" @click="submitLogin">登录</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      form: {
        account: '',
        password: ''
      }
    }
  },
  methods:{
    ...mapActions([
      'LOGIN'
    ]),
    submitLogin(){
      if(!this.form.account){
        this.$message.info('请输入账号')
        return false
      }
      if(this.form.password != this.form.account+'123'){
        this.$message.info('密码不正确')
        return false
      }
      this.LOGIN(this.form).then(res=>{
        setTimeout(()=>{
          this.$router.push('/')
        },100)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  width: 400px;
  height: 300px;
  // border: 1px solid #66ccff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-70%);
  box-shadow: 0 0 3px #66ccff;
  color: #333;
  .tit{
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .form{
    text-align: center;
    .login-btn{
      margin-top: 50px;
      .btn{
        width: 250px;
      }
    }
  }
}
</style>