<template>
  <div class="center-bg">
    <!-- <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"></transition> -->
    <img src="@/assets/images/center-bg1.png" class="bg1" alt="">
    <img src="@/assets/images/center-bg2.png" class="bg2" alt="">
    <img src="@/assets/images/center-bg3.png" class="bg3" alt="">
    <img src="@/assets/images/center-bg4.png" class="bg4" alt="">
    <img src="@/assets/images/center-bg5.png" class="bg5" alt="">
    <img src="@/assets/images/center-bg6.png" class="bg6" alt="">
    <img src="@/assets/images/center-bg7.png" class="bg7" alt="">
    <img src="@/assets/images/center-bg8.png" class="bg8" alt="">
    <div class="num">{{status}}</div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String | Number,
      default: '检测中'
    }
  },
  methods: {
    clicklogin(){
      alert(1)
      this.$router.push({
        name: 'Workplace',
        params:{
          id: 1
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.center-bg{
  width: 4.88rem;
  height: 4.88rem;
  position: absolute;
  top: 50%;
  left: 7.06rem;
  transform: translateY(-50%);
  .num{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    transform: translate(-50%,-50%);
    font-size: 0.4rem;
    // color: #fff;
  }
  .bg1{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: rotate-positive 12s linear infinite;
  }
  .bg2{
    position: absolute;
    left: 8%;
    top: 8%;
    width: 84%;
    height: 84%;
    animation: rotate-negative 14s linear infinite;
  }
  .bg3{
    position: absolute;
    left: 12%;
    top: 12%;
    width: 76%;
    height: 76%;
    animation: rotate-positive 20s linear infinite;
  }
  .bg4{
    position: absolute;
    left: 15%;
    top: 15%;
    width: 70%;
    height: 70%;
    animation: rotate-negative 20s linear infinite;
  }
  .bg5{
    position: absolute;
    left: 17.5%;
    top: 17.5%;
    width: 65%;
    height: 65%;
    animation: rotate-positive 38s linear infinite;
  }
  .bg6{
    position: absolute;
    left: 20.2%;
    top: 20.2%;
    width: 59.6%;
    height: 59.6%;
    animation: rotate-negative 36s linear infinite;
  }
  .bg7{
    position: absolute;
    left: 24%;
    top: 24%;
    width: 52%;
    height: 52%;
    animation: rotate-positive 32s linear infinite;
  }
  .bg8{
    position: absolute;
    right: 16%;
    top: 19%;
    height: 64%;
  }
  
}
@keyframes rotate-positive{
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate-negative{
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(-360deg);
  }
}
</style>
