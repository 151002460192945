import request from '@/utils/request'

// 用户详情
export function getListInfo (parameter) {
  return request({
    url: 'https://api.anjihos.z-y.tech/bi/bigdata/getListInfo',
    method: 'get',
    params: parameter
  })
}

