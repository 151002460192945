<template>
  <div class="screen">
    <top-left />
    <top-right />

    <center-left-echarts ref="centerLeftEcharts" />
    <center-left :form="form" />
    <screenCenter :status="status" />
    <center-right :form="form" />
    <center-right-echarts ref="centerRightEcharts" />

    <bottom-left />
    <bottom-right />
  </div>
</template>

<script>
import { getListInfo } from "@/api/userInfo.js";
// import screenfull from 'screenfull'
import screenCenter from "./components/center";
import topLeft from "./components/topLeft";
import topRight from "./components/topRight";
import bottomLeft from "./components/bottomLeft";
import bottomRight from "./components/bottomRight";
import centerRight from "./components/centerRight";
import centerLeft from "./components/centerLeft";
import centerLeftEcharts from "./components/centerLeftEcharts";
import centerRightEcharts from "./components/centerRightEcharts";
import { mapState } from 'vuex'

import mqtt from "mqtt";



export default {
  components: {
    screenCenter,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    centerRight,
    centerLeft,
    centerLeftEcharts,
    centerRightEcharts,
  },
  data() {
    return {
      form: {
        身高: '-',
        体重: '-',
        体温: '-',
        腰围: '-',
        血压: '-',
        心率: '-',
        血糖: '-',
        BMI: '-',
      },
      status: '-',
      sfzh: "330523194907016227",

      doctor_team: {},
      userInfo: {},
      bloodPressureTop: [],
      bloodPressureBot: [],
      bloodPressureTime: [],

      bloodSugarTop: [],
      bloodSugarBot: [],
      bloodSugarTime: [],
    };
  },
  computed: {
    ...mapState([
      'healthHouseDeviceName'
    ])
  },
  methods: {
    reset() {
      this.form = {
        身高: '-',
        体重: '-',
        体温: '-',
        腰围: '-',
        血压: '-',
        心率: '-',
        血糖: '-',
        BMI: '-',
      };
    },
    getListInfo() {
      getListInfo({
        sfzh: this.sfzh,
      }).then((res) => {
        console.log(res.data);
        this.doctor_team = res.data.doctor_team;
        this.userInfo = res.data.jbxx;
        const vitalsign_blood_pressure = res.data.vitalsign_blood_pressure;
        const vitalsign_blood_sugar = res.data.vitalsign_blood_sugar;
        this.bloodPressureTop = [];
        this.bloodPressureBot = [];
        this.bloodPressureTime = [];
        vitalsign_blood_pressure.map((item) => {
          if (item.name == "高压") {
            this.bloodPressureTop.push(item.value);
            this.bloodPressureTime.push(item.day);
          } else {
            this.bloodPressureBot.push(item.value);
          }
        });

        this.bloodSugarTop = [];
        this.bloodSugarBot = [];
        this.bloodSugarTime = [];
        vitalsign_blood_sugar.map((item) => {
          if (item.name == "非空腹血糖") {
            this.bloodSugarTop.push(item.value);
            this.bloodSugarTime.push(item.day);
          } else {
            this.bloodSugarBot.push(item.value);
          }
        });

        this.$nextTick(() => {
          this.$refs.centerLeftEcharts.initData({
            top: this.bloodPressureTop,
            bot: this.bloodPressureBot,
            time: this.bloodPressureTime,
          });

          this.$refs.centerRightEcharts.initData({
            top: this.bloodSugarTop,
            bot: this.bloodSugarBot,
            time: this.bloodSugarTime,
          });
        });
      });
    },

    // screen(){
    //   // 如果不允许进入全屏，发出不允许提示
    //   if (!screenfull.enabled) {
    //     console.log('您的浏览器不能全屏');
    //     return false
    //   }
    //   screenfull.toggle();

    // }
    sendMse() {
      client.publish(
        "hh-origin",
        JSON.stringify({
          blood_preasure_high: "000",
          blood_preasure_low: "000",
          heart_rate: "000",
        }),
        { qos: 0, retain: false },
        (error2) => {
          console.log("发送消息", error2);
          if (error2) {
            console.log("Publish error", error2);
          }
        }
      );
    },
    mqttMsg() {
      var client;
      const options = {
        connectTimeout: 40000,
        clean: true,
        name: "fsd",
        clientId: null,
        username: "admin",
        password: "admin123",
      };
      // client = mqtt.connect("ws://106.14.248.81:8083/mqtt", options);
      client = mqtt.connect("ws://106.15.62.8:8083/mqtt", options);


      let that = this;
      client.on("connect", (e) => {
        console.log("连接成功！！！");

        client.subscribe(that.healthHouseDeviceName, (error) => { //hh-origin
          if (!error) {
            console.log("订阅成功-" + that.healthHouseDeviceName);
          } else {
            console.log("订阅失败-" + that.healthHouseDeviceName);
          }
        });
        // client.subscribe("hh-devicedata", (error) => {
        //   if (!error) {
        //     console.log("订阅成功-hh-devicedata");
        //   } else {
        //     console.log("订阅失败-hh-devicedat");
        //   }
        // });
      });
      // 接收消息处理
      client.on("message", (topic, message) => {
        console.log("收到来自", topic, "的消息", message.toString());

        try {
          const params = JSON.parse(message.toString());
          console.log(params);
          if(topic == that.healthHouseDeviceName){
            if(params.action == 'DeviceData'){
              if(params.血压) that.handlingExceptions('血压',params.血压)
              if(params.心率) that.handlingExceptions('心率',params.心率)
              if(params.体温) that.handlingExceptions('体温',params.体温)
              if(params.血糖) that.handlingExceptions('血糖',params.血糖)
              if(params.尿酸) that.handlingExceptions('尿酸',params.尿酸)
              if(params.血氧) that.handlingExceptions('血氧',params.血氧)
              if(params.总胆固醇) that.handlingExceptions('总胆固醇',params.总胆固醇)
              if(params.心电) that.handlingExceptions('心电',params.心电)
              if(params.体脂率) that.handlingExceptions('体脂率',params.体脂率)
              if(params.腰围) that.handlingExceptions('腰围',params.腰围)
              if(params.BMI) that.handlingExceptions('BMI',params.BMI)
              if(params.身高) that.handlingExceptions('身高',params.身高)
              if(params.体重) that.handlingExceptions('体重',params.体重)
              if(params.中医四诊) that.handlingExceptions('中医四诊',params.中医四诊)


              // if (params.blood_preasure_high)
              //   that.form.blood_preasure_high = params.blood_preasure_high;
              // if (params.blood_preasure_low)
              //   that.form.blood_preasure_low = params.blood_preasure_low;
              // if (params.heart_rate) that.form.heart_rate = params.heart_rate;
              // if (params.blood_oxygen) that.form.blood_oxygen = params.blood_oxygen;

              // if (params.waist) that.form.waist = params.waist;
              // if (params.height) that.form.height = params.height;
              // if (params.weight) that.form.weight = params.weight;
              // if (params.fatrate) that.form.fatrate = params.fatrate;
            }
            
            if (params.sfzh && params.action == "start") {
              that.reset();
              that.status = '检测中'
              that.sfzh = params.sfzh;
              that.getListInfo();
            }
            if(params.action == "end"){
              that.status = '-'
              that.reset();
            }
          }
          
        } catch (error) {
          console.log(error);
        }
      });
    },
    handlingExceptions(name,str){
      let arr = str.split(',')
      this.form[name] = arr[0]
      // if(arr.length == 1){
      //   this.wrongForm[name] = false
      // } else {
      //   this.wrongForm[name] = true
      // }
    },
  },
  created() {
    this.getListInfo();

    // this.mqttMsg();
    if(this.healthHouseDeviceName){
      console.log('连接')

      this.mqttMsg();
    } else {
      this.$router.push('/login')
    }
  },
};
</script>

<style lang="less" scoped>
.screen {
  width: 100%;
  height: 5.5rem;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  position: relative;
}
</style>