<template>
  <div class="time">2021年11月29日 16:11</div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.time{
  height: 0.31rem;
  line-height: 0.31rem;
  font-weight: bold;
  font-size: 0.15rem;
  position: absolute;
  bottom: 0.47rem;
  right: 1.11rem;
  padding: 0 0.07rem;
}
</style>