<template>
  <div id="centerRightEcharts"></div>
</template>

<script>
export default {
  data(){
    return {
      bloodSugarTop: [],
      bloodSugarBot: [],
      bloodSugarTime: []
    }
  },
  methods:{
    initData(data){
      console.log(data)
      this.bloodSugarTop = data.top
      this.bloodSugarBot = data.bot
      this.bloodSugarTime = data.time
      this.initEcharts()
    },
    initEcharts(){
      this.threePlatesEchart = this.$echarts.init(
        document.getElementById("centerRightEcharts")
      );
      this.threePlatesEchart.setOption({
        title: {
          text: '血糖',
          textStyle: {
            //图例文字的样式
            color: "#fff"
          },
        },
        legend: {
          y: "top",
          itemWidth: 18,
          itemHeight: 6,
          itemGap: 10, //间距
          icon: 'rect',//图标样式
          textStyle: {
            //图例文字的样式
            color: "#5D9EAD",
            fontSize: 12
          },
          data: ['非空腹血糖','空腹血糖']
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          top: "55",
          left: "15",
          right: "15",
          bottom: "5",
          containLabel: true,
        },
        xAxis: {
          name: "",
          type: "category",
          data: this.bloodSugarTime,
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#ADD5DE",
              width: "1"
            }
          },
          //网格线
          splitLine: {
            show: true,
            lineStyle: {
              width: "1",
              color: "rgba(106, 137, 215, 0.1)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            // interval: 0,
            // rotate: -50,
            textStyle: {
              color: "#ADD5DE",
              fontSize: 12
            },
          }
        },
        yAxis: {
          name: "",
          gridIndex: 0,
          axisLine: {
            lineStyle: {
              color: "#ADD5DE",
              width: "1"
            }
          },
          //网格线
          splitLine: {
            show: true,
            lineStyle: {
              width: "1",
              color: "rgba(106, 137, 215, 0.1)"
            }
          },
          axisTick: {
            //刻度线
            show: false
          },
          axisLabel: {
            textStyle: {
              align: "right",
              baseline: "middle",
              color: "#ADD5DE",
              fontSize: 12
            }
          }
        },
        series: [
          {
            name: '非空腹血糖',
            data: this.bloodSugarTop,
            type: 'line',
            smooth: true,
            symbol: 'none'
          },
          {
            name: '空腹血糖',
            data: this.bloodSugarBot,
            type: 'line',
            smooth: true,
            symbol: 'none'
          }
        ]
        // this.plateOfData.yData.map((item,index)=>{
        //   let color = ["#20DBEE","#42E1A6",'#3271FF']
        //   // let num = [10,15,20]
        //   return {...item,
        //   ...{
        //     type: "line", 
        //     smooth: true, 
        //     showSymbol: false, 
        //     seriesLayoutBy: "row", 
        //     barWidth: 8, 
        //     color: color[index],
        //     markLine : {
        //         symbol:"none",               //去掉警戒线最后面的箭头
        //         data : [{
        //             silent:false,             //鼠标悬停事件  true没有，false有
        //             label:{
        //               position:"start",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
        //               formatter: ""
        //             },
        //             lineStyle:{               //警戒线的样式  ，虚实  颜色
        //                 type:"dashed",
        //                 color:color[index]
        //             },
        //             yAxis: this.plateCordon[index].value
        //         }]
        //       }
        //     }
        //   }
        // })

      });
    }
  },
  mounted(){
    this.initEcharts()
  }
}
</script>

<style lang="less" scoped>
#centerRightEcharts{
  width: 2.7rem;
  height: 2.1rem;
  position: absolute;
  top: 2rem;
  // border: 1px solid #333;
  right: 1rem;
}
</style>