<template>
  <div class="topLeft">
    <div class="once" v-for="(item,index) in list" :key="index">
      <div class="name">{{item.name}}</div>
      <div class="img">
        <img :src="item.img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: '通信正常', img: require('@/assets/images/top-l1.png')},
        { name: '通信异常', img: require('@/assets/images/top-l2.png')},
        { name: '通信正常', img: require('@/assets/images/top-l3.png')},
        { name: '通信正常', img: require('@/assets/images/top-l4.png')},
        { name: '通信正常', img: require('@/assets/images/top-l5.png')}
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.topLeft{
  display: flex;
  position: absolute;
  top: 0.3rem;
  left: 1.1rem;
  .once{
    margin: 0 0.11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name{
      color: #ffb8ff;
      font-size: 0.12rem;
    }
    .img{
      width: 0.5rem;
      height: 0.57rem;
      margin-top: 0.13rem;
      display: flex;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>